import React from "react"
import { StaticQuery, graphql, Link } from 'gatsby'

import Layout from "../components/layout"

import ImageRow from "../components/image-row"
import Break from "../components/break"
import Titles from "../components/titles"
import SEO from "../components/seo"
import Carousel from "../components/carousel"


const gql_query = graphql`{
  artists: craftAPI { entries(type: "artist") {
    ... on CraftAPI_artist_artist_Entry {
      id
      slug
      title
      images {
        ... on CraftAPI_artworks_Asset {
          id
          img
          filename
          url
        }
      }
    }

}}}`
const artist_rows = (artists) => {

  let artist_rows = artists.reduce((resultArray, item, index) => { 
    const chunkIndex = Math.floor(index/3)

    if(!resultArray[chunkIndex]) {
    resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push({
    type:"stacked", 
    link:"/" + item.slug,
    url:item.images?.[0]?.url,
    details:<div><div className="title">{item.title}</div></div>
    })

    return resultArray
    }, []).map((row) => <ImageRow  forceLength={3} images={row} /> )
  
  
  
  
  return artist_rows
}
const hero = <Carousel  />
const Artists = () => (
        <StaticQuery query={gql_query} render={(data) => {
            
          
  return <Layout hero={undefined} intro={false}>
    <SEO title="Home" />
    <div style={{ }}>
      
  <h1 style={{marginBottom:"60px", fontSize:"36px",marginTop:"80px", textAlign:"center"}}>Artists</h1>
      {artist_rows(data.artists.entries)}


          

    </div>
  </Layout>}} />
)

export default Artists
